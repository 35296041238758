import React, {FC} from 'react';
import {
	Badge,
	Box,
	Button,
	Heading,
	HStack,
	Icon,
	Link,
	Tag,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import parseISO from 'date-fns/parseISO';
import {MdCheckCircle, MdInfoOutline} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import TouchableHover from '../../../components/TouchableHover';
import RequestTimeframes from '../../../enums/RequestTimeframes';
import {Puddleglum} from '../../../puddleglum';
import {isTomorrowOrLater} from '../../../utils/date';
import {AppointmentDate} from '../../../utils/format';

const MyAppointments: FC = () => {
	const navigate = useNavigate();

	const {data: bookings} = useQuery(['bookings'], async () => {
		const {data} = await Puddleglum.Controllers.Employee.BookingController.index({
			include_recent_cancellations: 1,
		});
		return data;
	});

	const editBookingRequest = (booking: Puddleglum.Models.BookingRequest) => {
		navigate('/booking-request', {
			state: {
				booking_request: booking,
				reason: booking.reason,
				organization_id: booking.organization_id,
				provider_id: booking.provider_id,
				location_id: booking.location_id,
				treatment_ids: booking.orders?.map((o) => o.treatment_id),
				treatment_group_ids: booking.orders?.map((o) => o.treatment_group_id),
			},
		});
	};

	const getBookingStatus = (booking: Puddleglum.Models.BookingRequest) => {
		if (booking.status === 'pending') {
			return 'Pending Request';
		}
		if (booking.status === 'confirmed') {
			return 'Scheduled Appointment';
		}
		return 'Canceled Appointment';
	};

	if (!bookings || bookings.length === 0) return null;

	return (
		<Box w='full'>
			<Heading mb={2} size='md' color='gray.500'>
				Appointments
			</Heading>
			<VStack spacing={2} w='full'>
				{bookings?.map((booking) => (
					<Box
						key={booking.id}
						w='full'
						p={4}
						border='solid 1px'
						borderColor='gray.200'
						borderRadius='md'
						bgColor={booking.status === 'pending' ? 'yellow.50' : 'white'}
					>
						<HStack w='full' justifyContent='space-between'>
							<Text fontSize='sm' color='gray.500' fontWeight='medium'>
								{getBookingStatus(booking)}
							</Text>
							{booking.needs_review && (
								<TouchableHover label='Because of the estimated price for this service, this is currently being reviewed by the Millennia team.'>
									<Badge colorScheme='yellow'>Under Review</Badge>
								</TouchableHover>
							)}
							<Text fontSize='sm' color='cyan.500' fontWeight='semibold'>
								{booking.starts_at
									? AppointmentDate(parseISO(booking.starts_at))
									: RequestTimeframes[
											booking.requested_timeframe as keyof typeof RequestTimeframes
										]}
							</Text>
						</HStack>

						<HStack w='full' justifyContent='space-between'>
							<Heading mt='2' size='md' fontWeight='medium'>
								{booking.organization?.name}
							</Heading>
							<Box>
								{booking.status === 'confirmed' && (
									<TouchableHover label='Confirmed' aria-label='Confirmed'>
										{/* Span is needed so the tooltip appears in the right place */}
										<span>
											<Icon
												boxSize={5}
												as={MdCheckCircle}
												color='green.500'
											/>
										</span>
									</TouchableHover>
								)}
								{booking.status === 'pending' && (
									<TouchableHover
										label='This appointment is still pending.'
										aria-label='Pending'
									>
										<span>
											<Icon
												boxSize={5}
												as={MdInfoOutline}
												color='orange.500'
											/>
										</span>
									</TouchableHover>
								)}
							</Box>
						</HStack>

						<Wrap>
							{(booking.orders ?? [])
								.filter((order) => order.status !== 'canceled')
								.map((order) => (
									<WrapItem key={order.id}>
										<Tag
											overflow='hidden'
											whiteSpace='nowrap'
											maxW='200px'
											colorScheme={
												Number(
													order.billing_statement?.employee_balance,
												) === 0
													? 'green'
													: 'gray'
											}
										>
											{order.treatment
												? order.treatment.treatment
												: order.treatment_group?.name}
										</Tag>
									</WrapItem>
								))}
						</Wrap>

						<HStack mt='2' w='full' justifyContent='space-evenly'>
							{(booking.status === 'pending' || booking.status === 'confirmed') && (
								<Button
									colorScheme='cyan'
									color='white'
									w='full'
									onClick={() => editBookingRequest(booking)}
								>
									Change
								</Button>
							)}
							{booking.status === 'confirmed' &&
								isTomorrowOrLater(parseISO(booking.starts_at!)) && (
									<Button
										colorScheme='blue'
										color='white'
										w='full'
										onClick={() => editBookingRequest(booking)}
									>
										View Details
									</Button>
								)}
							<Button
								variant='outline'
								w='full'
								as={Link}
								href={`tel:${booking.organization?.phone}`}
							>
								Call Office
							</Button>
						</HStack>
					</Box>
				))}
			</VStack>
		</Box>
	);
};

export default MyAppointments;
