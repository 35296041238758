import React, {FC} from 'react';
import {VStack} from '@chakra-ui/react';
import useMe from '../../../hooks/useMe';
import BookingRequestFollowUpModal from '../components/BookingRequestFollowUpModal';
import MedicalCareSearch from '../components/MedicalCareSearch';
import MobilePageContainer from '../components/MobilePageContainer';
import MyAppointments from '../components/MyAppointments';
import MyProviders from '../components/MyProviders';
import Page from '../components/Page';
import PendingBillingStatements from '../components/PendingBillingStatements';
import RecentPayments from '../components/RecentPayments';

const PatientPortalHomeScreen: FC = () => {
	const {me} = useMe();

	if (!me) return null;

	return (
		<Page overflow='auto'>
			<MobilePageContainer>
				<VStack w='full' spacing={8} alignItems='flex-start'>
					<RecentPayments />
					<MedicalCareSearch />
					<PendingBillingStatements />
					<MyAppointments />
					<MyProviders />
					<BookingRequestFollowUpModal />
				</VStack>
			</MobilePageContainer>
		</Page>
	);
};

export default PatientPortalHomeScreen;
